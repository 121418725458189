export const environment = {
  production: false,
  website   : 'https://ufs-eg.com/',
  //publicUrl   : window.location.origin,
  //apiUrl: window.location.origin + '/api',//'http://localhost/UFS-Back/public/api',
  publicUrl   : 'http://localhost/UFS-Back/public',
  apiUrl: 'https://ufs-eg.com/api',
  backEndPublicUrl: 'http://localhost:4000/',
  googleApiKey: 'AIzaSyAtCTUfmq6JOFrE9Ib_HPEPFhUE9VdwcEs'
};

